import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from './layout/GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';
import { Button } from './layout/StyledComponents';
import rectangle from '../images/svgs/rectangle.svg';
import girl from '../images/homepage.jpg';
import { css, cx } from '@emotion/css';

const StyledGetInTouch = styled.div`
    justify-content: center;
    form {
        padding: 4em;
        background: #efefef;
        /* Card-drop-shadow */

        box-shadow: 0px 4px 120px rgba(89, 113, 105, 0.1);
        border-radius: 16px;
    }
    .text-container {
        padding: 1em 0.75em;
    }
    .icons {
        display: none;
        width: 700px;
    }
    h4 {
        width: 75%;
    }
    form {
        margin-top: 2em;
        .item {
            display: flex;
            flex-direction: column;
        }
        label {
            font-size: 14px;
        }
        button {
            margin-top: 1em;
            width: 100%;
        }
        .buttons {
            display: flex;
            padding: 0em 0em;
            flex-direction: column;
            justify-content: space-between;
        }
        input,
        textarea {
            text-indent: 12px;
            margin: 1em 0em;
            padding: 1em 0.25em;
            background: #f9f9f9;
            border: 1px solid #e6e6e6;
            box-sizing: border-box;
            border-radius: 5px;
        }
    }
    @media ${device.tablet} {
        form {
            .buttons {
                flex-direction: row;
                padding: 0em 4em;
                button {
                    width: 45%;
                }
            }
        }
        .icons {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 5em;
            width: 700px;
        }
        .icons-item {
            p {
                text-decoration: underline;
                margin-top: 1.5em;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 2.75em 0em;
        }
        padding: 4em;
        h4 {
            line-height: 65px;
            font-size: 50px;
        }
    }
    @media ${device.laptop} {
        display: flex;
        padding: 0em;
        form {
            .buttons {
                flex-direction: row;
                padding: 2em 0em 0em;
                width: 500px;

                margin: 0 auto;
                button {
                    margin: 0em 1em;
                    min-width: 40%;
                }
            }
        }
        .text-container {
            padding-right: 4em;
            min-width: 40%;
        }
    }
`;

export const GetInTouch: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };

    return (
        <StyledGetInTouch>
            <div className="icons">
                <a rel="noopener noreferrer" href="tel:8132354143">
                    <div className="icons-item">
                        <StaticImage
                            placeholder="none"
                            src="../images/svgs/mobile-black.svg"
                            alt="mobile black icon"
                        />
                        <p>(813) 235-4143</p>
                    </div>
                </a>
                <a target="_blank" rel="noopener noreferrer" href="mailto:info@craftyoursmile.com">
                    <div className="icons-item">
                        <StaticImage
                            placeholder="none"
                            src="../images/svgs/mail-black.svg"
                            alt="mail black icon"
                        />
                        <p>info@craftyoursmile.com</p>
                    </div>
                </a>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/1942+Highland+Oaks+Blvd,+Lutz,+FL+33559/data=!4m2!3m1!1s0x88c2ba29bb8ba8f5:0xa5cb651cf1a0464d?sa=X&ved=2ahUKEwiIkOORj5H2AhUVlYkEHahrCugQ8gF6BAgSEAE"
                >
                    <div className="icons-item">
                        <StaticImage
                            placeholder="none"
                            src="../images/svgs/map-black.svg"
                            alt="map black icon"
                        />
                        <p>1942 Highland Oaks Blvd Suite B, Lutz, FL 33559</p>
                    </div>
                </a>
            </div>
            <div className="text-container">
                <form onSubmit={onSubmit}>
                    <h4>Get In Touch</h4>
                    <div className="item">
                        <label htmlFor="name">Name*</label>
                        <input
                            required
                            placeholder="Full Name *"
                            type="text"
                            id="name"
                            name="name"
                        />
                    </div>
                    <div className="item">
                        <label htmlFor="email">Email address *</label>
                        <input
                            required
                            placeholder="example@gmail.com"
                            type="email"
                            id="email"
                            name="email"
                        />
                    </div>
                    <div className="item">
                        <label htmlFor="feedback">Message/Feedback *</label>
                        <textarea name="feedback" id="feedback" />
                    </div>
                    <div className="buttons">
                        <Button color="gold">
                            <a style={{ color: `${colors.gold}` }} href="tel:8132354143">
                                Call us Instead
                            </a>
                        </Button>
                        {submitState === 'submitted' ? (
                            <>
                                <Button
                                    disabled
                                    color="blue"
                                    style={{ cursor: 'auto', color: `${colors.white}` }}
                                >
                                    Thank you!
                                </Button>
                            </>
                        ) : (
                            <Button color="blue" type="submit">
                                {submitState === 'submitting'
                                    ? 'Sending message...'
                                    : 'Get In Touch'}
                            </Button>
                        )}
                    </div>
                </form>
            </div>
        </StyledGetInTouch>
    );
};
