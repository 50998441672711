import { Link } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { RegularHero } from '../components/RegularHero';
import { GetInTouch } from '../components/GetInTouch';
import { AppointmentTwo } from '../components/layout/Appointment-two';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';

const Contact: Page = () => (
    <>
        <DefaultLayout>
            <Seo
                title="Contact Us in Lutz, FL | Smilecraft Dental Studio"
                description="You can contact us At SmileCraft anytime. We are always there for support or help with any question you may have."
            />
            <RegularHero prevPage="home" currentPage="Contact Us">
                <StaticImage
                    quality={100}
                    className="regular-hero-image"
                    src="../images/plants.jpg"
                    placeholder="blurred"
                    alt="green plants"
                />
            </RegularHero>
            <GetInTouch />
        </DefaultLayout>
    </>
);

export default Contact;
